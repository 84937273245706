.blockEnd {
    display: block;
    margin-left: auto;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.flexStart {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
    width: 100%;
}

.paddingVertical32 {
    padding: 32px 0;
}

.width50 {
    width: 50%;
}

.width100 {
    width: 100%;
}